import type { GetStaticProps } from 'next'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React from 'react'
import { Input } from '../components/global/form/input'
import MainLayout from '../components/global/layouts/MainLayout'
import LegacyContainer from '../components/global/legacy/container/LegacyContainer'
import LegacyHomeIcon from '../components/global/legacy/icons/LegacyHomeIcon'
import NostoRecommendations from '../components/global/nosto/NostoRecommendations'
import useNostoNotFoundPage from '../lib/clientOnly/nosto/useNostoNotFoundPage'
import COMPANY_INFO from '../lib/constants/COMPANY_INFO'
import routes from '../lib/routes'
import mainLayout_getStaticProps from '../lib/serverOnly/pageTypes/global/mainLayout/mainLayout_getStaticProps'

// pages/404.js
export default function Custom404(props) {
  useNostoNotFoundPage()

  const router = useRouter()
  const [query, setQuery] = React.useState('')

  return (
    <>
      <Head>
        <title>{COMPANY_INFO.name} | Page Not Found</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <MainLayout
        staticProps={props.layoutStaticProps}
        crumbs={[
          {
            label: (
              <div className="flex flex-row items-center">
                <LegacyHomeIcon className="mr-2 h-5 w-5 text-gray-700" />
                Home
              </div>
            ),
            path: '/',
          },
          {
            label: 'Error',
            path: '/error',
          },
        ]}
      >
        <LegacyContainer>
          <div className="mx-auto mb-16 mt-4 w-3/4 max-w-7xl text-center text-coolGray">
            <h1 className="mb-8 text-2xl font-semibold lg:text-[2.125rem]">
              404 Error - Page not found
            </h1>

            <p className="mb-5 text-lg">
              Uh oh, looks like the page you are looking for has moved or no longer exists.
            </p>

            <form
              className="flex flex-col gap-4 md:flex-row"
              onSubmit={(e) => {
                e.preventDefault()
                if (query.trim()) {
                  router.push({
                    pathname: routes.search(),
                    query: {
                      search_query_adv: query,
                    },
                  })
                }
              }}
            >
              <Input
                id="search"
                label="Search products"
                hideLabel
                type="search"
                value={query}
                onChangeText={setQuery}
              />
              <button
                type="submit"
                className="min-w-[260px] rounded-lg bg-primary px-6 py-[1.125rem] text-2xl font-semibold text-white transition hover:opacity-80"
              >
                Search
              </button>
            </form>
          </div>

          <div className="space-y-6">
            <NostoRecommendations placement="notfound-nosto-1" />
            <NostoRecommendations placement="notfound-nosto-2" />
          </div>
        </LegacyContainer>
      </MainLayout>
    </>
  )
}

// 404 page CANNOT use getServerSideProps & therefore must be generated statically.
export const getStaticProps: GetStaticProps = async function not_found_page_props() {
  const layoutStaticProps = await mainLayout_getStaticProps()

  return {
    props: { layoutStaticProps },
    revalidate: 300,
  }
}
