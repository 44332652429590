export default function LegacyHomeIcon({ className }: { className?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-10 0 961 1024" className={className}>
      <path
        fill="currentColor"
        d="M805 576v274q0 15 -11 26t-26 11h-219v-220h-147v220h-219q-15 0 -26 -11t-11 -26v-274q0 -1 0.5 -2l0.5 -1l328 -271l329 271l0.5 1t0.5 2v0zM932 537l-35 42l-6 4t-6 2h-2q-4 0 -7 -1t-5 -3l-396 -330l-395 330q-3 2 -6.5 3.5t-7.5 0.5q-3 0 -6.5 -2t-5.5 -4l-35 -42
  q-5 -6 -4 -13.5t6 -12.5l411 -342q18 -15 43.5 -15t43.5 15l139 116v-111q0 -8 5.5 -13.5t13.5 -5.5h109q8 0 13.5 5.5t5.5 13.5v233l125 104q5 5 6 12.5t-4 13.5v0z"
      />
    </svg>
  )
}
