import clsx from 'clsx'
import type { ChangeEventHandler, FocusEventHandler } from 'react'
import { forwardRef } from 'react'
import type { FieldError } from 'react-hook-form/dist/types/errors'
import { FormError, formatError } from './FormError'

export type InputProps = {
  analyticsErrorAction?: string
  // TODO: This is backwards! It should be className for the outer container, and then inputClassName for the actual input
  containerClassName?: string
  className?: string
  defaultValue?: string
  disabled?: boolean
  error?: string | FieldError
  hideLabel?: boolean
  id: string
  label: string
  name?: string
  onBlur?: FocusEventHandler<HTMLInputElement>
  onChange?: ChangeEventHandler<HTMLInputElement>
  onChangeText?: (nextValue: string) => void
  placeholder?: string
  required?: boolean
  type?: string
  value?: string
  maxLength?: number
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      analyticsErrorAction,
      containerClassName,
      className,
      defaultValue,
      disabled,
      error,
      hideLabel = false,
      id,
      label,
      name,
      onBlur,
      onChange,
      onChangeText,
      placeholder,
      required = false,
      type = 'text',
      value,
      maxLength,
    },
    ref
  ) => {
    const formattedError = formatError(error)
    const severity = formattedError.includes('?') ? 'warning' : 'error'

    return (
      <div className={clsx(containerClassName, 'space-y-2')}>
        <label
          className={clsx(hideLabel ? 'visually-hidden' : 'block w-full text-xl')}
          htmlFor={id}
        >
          {label}
          {required ? (
            <abbr className="ml-1 text-primary-900" aria-label="Required">
              *
            </abbr>
          ) : null}
        </label>

        <input
          ref={ref}
          defaultValue={defaultValue}
          maxLength={maxLength}
          disabled={disabled}
          className={clsx(
            'block w-full py-4 px-4',
            error ?
              severity === 'warning'
                ? `border-form-warning bg-yellow-50 text-form-warning placeholder-yellow-300 focus:ring-form-warning focus:ring-opacity-30 `
                : `border-red-600 bg-red-50 text-red-600 placeholder-red-300 focus:ring-red-500 focus:ring-opacity-30 `
              : `border-primaryLegacy-light bg-primary-100 focus:ring-primaryLegacy-lighter`,
            'focus:outline-none rounded border text-xl focus:ring-2',
            disabled && 'cursor-not-allowed opacity-50',
            className
          )}
          placeholder={placeholder}
          value={value}
          name={name}
          type={type}
          id={id}
          onBlur={onBlur}
          onChange={(e) => {
            onChange?.(e)
            onChangeText?.(e.target.value)
          }}
          required={required}
        />

        <FormError error={error} severity={severity} label={label} analyticsAction={analyticsErrorAction} />
      </div>
    )
  }
)

export const EmailInput = forwardRef<HTMLInputElement, Omit<InputProps, 'type'>>((props, ref) => (
  <Input {...props} ref={ref} type="email" />
))

export const PasswordInput = forwardRef<HTMLInputElement, Omit<InputProps, 'type'>>(
  (props, ref) => <Input {...props} ref={ref} type="password" />
)

export const PhoneInput = forwardRef<HTMLInputElement, Omit<InputProps, 'type'>>((props, ref) => (
  <Input {...props} ref={ref} type="tel" />
))
