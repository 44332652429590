import { useEffect } from 'react'
import { useNostoContext } from '../../../components/global/nosto/NostoContext'
import useNostoRecommendations from './useNostoRecommendations'

export default function useNostoNotFoundPage() {
  const { setPageType, loadRecs } = useNostoContext()
  useEffect(() => {
    //Clear previous recommendations and then setup the page
    loadRecs(null)
    setPageType('404')
  }, [setPageType, loadRecs])
  useNostoRecommendations()
}
